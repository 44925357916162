//
// Text emphasis
// --------------------------------------------------

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }

  a#{$parent} {
    &.link-fx::before {
      background-color: $color !important;
    }

    &:hover,
    &:focus {
      color: darken($color, 10%) !important;
    }
  }
}
