//
// Item
// --------------------------------------------------

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;

  @at-root {
    a#{&} {
      will-change: opacity;

      &:hover,
      &:focus {
        opacity: 0.6;
      }

      &:active {
        opacity: 1;
      }

      &.item-link-pop {
        will-change: transform;

        &:hover,
        &:focus {
          opacity: 1;
          transform: scale(1.1);
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }

  &.item-tiny {
    width: 1rem;
    height: 1rem;
  }

  &.item-2x {
    width: 6rem;
    height: 6rem;
  }

  &.item-3x {
    width: 8rem;
    height: 8rem;
  }

  &.item-circle {
    border-radius: 50%;
  }

  &.item-rounded {
    border-radius: $border-radius;
  }

  &.item-rounded-lg {
    border-radius: 1.5rem;

    &.item-2x {
      border-radius: 2rem;
    }

    &.item-3x {
      border-radius: 3rem;
    }
  }
}
