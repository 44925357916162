//
// Custom Main
//
// Add your own styles or override existing ones
//
// This file is included last, so you have access
// to all Bootstrap and OneUI functions/mixins/styles etc
// --------------------------------------------------

// If you have many custom styles, you can create and import additional files
// For example you can create _component1.scss and include it as follows:
// @import 'component1';

.logo-login {
    width: 150px;
}

.ck-editor__editable_inline {
    min-height: 800px;
}

/* ATBD UPLOAD */
.media-import {
    margin-bottom: 1rem;

    &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100px;
        margin-bottom: 1rem;
        padding: 10px 10px;
        background-color: #ffffff;
        border: 2px dashed #C6D0DC;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
    }

    &__add {

    }

    &__file-plus {
        margin-right: .25rem;
        line-height: 1.25;
        font-weight: 500;
    }

    &__form {
        display: none;
    }

    &__input {

    }

    &__footer {
        text-align: center;
    }

    &__delete {
        font-size: .875rem;
        cursor: pointer;
    }

    &__trash {
        margin-right: .25rem;
    }
}

.highlight-icon {
    i {
        font-size: 55px;
    }
}

.message-limited {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
